<template>
  <main class="main">
    <div class="container">
      <div class="main__inner">
        <BreadcrumbsComponent title="Результаты нашей работы" />
        <section class="section section-pb48">
          <div class="filter__form-change-wrap wrap-pb">
            <form
              @submit.prevent="load"
              ref="filterForm"
              class="filter__form filter__form-change"
              autocomplete="off"
            >
              <div class="search filter filter__cols-3">
                <div class="search__input">
                  <label for="posters_search" class="search__label">Поиск</label>
                  <div class="search__input__wrap">
                    <input v-model="form.title.value" id="posters_search" type="text" maxlength="255" />
                    <button v-if="form.title.value" type="button" @click="resetSearchInput">
                      <CloseIcon />
                    </button>
                  </div>
                </div>
                <div class="filter__item filter__date">
                  <label>Диапазон дат</label>
                  <div class="filter__date__inputs">
                    <div class="filter__date__input">
                      <flat-pickr
                        v-model="form.start_date.value"
                        :config="flatPickrConfig"
                        placeholder="дд.мм.гггг"
                      >
                      </flat-pickr>
                      <DatePickIcon />
                    </div>
                    <div class="filter__date__input">
                      <flat-pickr
                        v-model="form.end_date.value"
                        :config="flatPickrConfig"
                        placeholder="дд.мм.гггг"
                      >
                      </flat-pickr>
                      <DatePickIcon />
                    </div>
                  </div>
                </div>
                <div class="search__btn search__btn--with-clear">
                  <a @click.prevent="resetFilterForm" href="#" class="filter__reset"> Сбросить фильтр </a>
                  <button type="submit" class="btn-blue_dark" :disabled="loading">
                    <LoadingIndicator v-if="loading" title="Загрузка" />
                    <template v-else>Найти</template>
                  </button>
                </div>
              </div>
            </form>
            <a
              @click.prevent="changeFilterForm"
              class="filter__change-btn"
              :class="{ hide__btn: isFilterVisible }"
              href="#"
            >
              <span v-if="isFilterVisible">Скрыть фильтр</span>
              <span v-else>Поиск и фильтр по результатам</span>
              <ArrowDownIcon />
            </a>
          </div>

          <LoadingIndicator v-if="loading" title="Загрузка" />
          <div v-else-if="scResults && scResults.data && scResults.data.length" class="sc__results-list">
            <div v-for="(item, index) in scResults.data" :key="index" class="sc__results-item">
              <div class="sc__results-image">
                <ImagesComparisonComponent
                  :leftImage="item.head_img | image($store.state.api)"
                  :rightImage="item.report_img | image($store.state.api)"
                />
              </div>
              <a v-if="item.address" href="#" class="sc__results-title">
                {{ item.address }}
              </a>
              <div v-if="item.category && item.category.title" class="sc__results-bottom">
                <span>{{ item.category.title }}</span>
                <time class="sc__results-item-date" :datetime="item.date_publication | robotDate">
                  {{ item.date_publication | humanDateRu }}
                </time>
              </div>
            </div>
          </div>
          <p v-else class="_item__text">Информация отсутствует</p>
          <PaginationComponent
            v-if="
              page !== null &&
              scResults &&
              scResults.paginatorInfo &&
              scResults.paginatorInfo.total !== null &&
              scResults.paginatorInfo.total > first
            "
            :total="Math.ceil(scResults.paginatorInfo.total / first)"
            @change="paginate"
            :page="page"
          />
        </section>
      </div>
    </div>
  </main>
</template>

<script>
import BreadcrumbsComponent from "components/BreadcrumbsComponent.vue";
import FlatPickr from "vue-flatpickr-component";
import { Russian } from "flatpickr/dist/l10n/ru.js";
import DatePickIcon from "@/components/svg/DatePickIcon.vue";
import LoadingIndicator from "@/components/LoadingIndicator.vue";
import CloseIcon from "@/components/svg/CloseIcon.vue";
import ImagesComparisonComponent from "components/ImagesComparisonComponent.vue";
import PaginationComponent from "@/components/Pagination.vue";
import PAGINATE_RESULTS from "@/graphql/sc/Results.graphql";
import ArrowDownIcon from "@/components/svg/ArrowDown.vue";

export default {
  name: "SocialControlResults",
  components: {
    BreadcrumbsComponent,
    FlatPickr,
    DatePickIcon,
    LoadingIndicator,
    CloseIcon,
    ImagesComparisonComponent,
    PaginationComponent,
    ArrowDownIcon,
  },
  data() {
    return {
      page: 1,
      first: 9,
      isFilterVisible: false,
      flatPickrConfig: {
        locale: Russian,
        enableTime: true,
        defaultHour: 0,
        defaultMinute: 0,
        dateFormat: "d.m.Y H:i",
        disableMobile: true,
      },
      loading: false,
      form: {
        title: {
          value: null,
          message: null,
        },
        start_date: {
          value: null,
          message: null,
        },
        end_date: {
          value: null,
          message: null,
        },
        category_id: {
          value: null,
          message: null,
        },
        code_gallery: {
          value: null,
          message: null,
        },
      },
    };
  },
  created() {
    // this.$store.dispatch("SC_RESULTS", this.$apollo.provider);
    this.load();
  },
  computed: {
    scResults() {
      return this.$store.state.socialControlResults;
    },
  },
  methods: {
    async load() {
      if (!this.loading) {
        this.loading = true;
        let data = {};
        Object.keys(this.form).forEach((key) => {
          if (this.form[key].value !== null) data[key] = this.form[key].value;
        });
        await this.$apollo.provider.clients.sc
          .query({
            query: PAGINATE_RESULTS,
            variables: Object.assign(data, {
              page: this.page,
              first: 9,
            }),
          })
          .then(({ data }) => {
            this.loading = false;
            if (data.PaginateProjects) {
              this.$store.commit("socialControlResults", data.PaginateProjects);
            }
          });
      }
    },
    paginate(e) {
      this.page = e;
      this.load();
    },
    changeFilterForm() {
      let filter = this.$refs.filterForm;
      filter.style.display = this.isFilterVisible ? "none" : "flex";
      this.isFilterVisible = !this.isFilterVisible;
    },
    /**
     * Сброс фильтра
     */
    resetFilterForm() {
      Object.keys(this.form).forEach((key) => {
        this.form[key].value = null;
      });
      this.$store.state.filterData = null;
      this.$store.state.filterResponse = null;
      this.$store.state.filterList = [];
      this.load();
    },
    /**
     * Очищение строки поиска
     */
    resetSearchInput() {
      this.form.title.value = null;
      this.load();
    },
  },
  metaInfo() {
    return this.$seo(
      "common",
      "Официальный сайт администрации города Махачкалы",
      "",
      "",
      "Официальный сайт администрации города Махачкалы",
      "",
      ""
    );
  },
};
</script>

<style lang="stylus">
@import '~flatpickr/dist/flatpickr.min.css'
@import '~vue-multiselect/dist/vue-multiselect.min.css'
@import "~@/styles/parts/sc_results.styl"
</style>
